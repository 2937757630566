import Layout from "components/layout/Layout";
import { PageHeader } from "components/PageHeader/PageHeader";
import { SEO } from "components/SEO";
import React from "react";

import "assets/styles/pages/privacy-policy-page.scss";

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <PageHeader title="Privacy policy" />

      <div className="privacy-policy-page" role="presentation">
        <section className="content__wrapper container">
          <div role="presentation">
            <h2>General Provisions</h2>
            <p>
              The Pannonian association is the coordinator of the Urban Sport
              and Culture Volunteers project. The project is a Collaborative
              Partnership co-funded by the Erasmus+ Sport Programme of the
              European Union in the amount of 400.000,00 EUR and will be
              implemented in the next 36 months in the beneficiary countries;
              Croatia, Ireland, Ukraine, Poland, Estonia and Slovenia between 7
              partners.
            </p>
          </div>

          <div role="presentation">
            <h2>Coordinator</h2>
            <p>Pannonian Association, Cara Hadrijana 5, 31000 Osijek.</p>
          </div>

          <div className="content__subsection" role="presentation">
            <div role="presentation">
              <h2>GDPR - General Data Protection Regulation</h2>
              <p>
                In connection with the start of application of Regulation (EU)
                2016/679 of the European Parliament and of the Council of 27
                April 2016 on the protection of individuals with regard to the
                processing of personal data and on the free movement of such
                data and the repeal of the Directive 95/46/EC (general
                regulation on data protection) (hereinafter "the Regulation") we
                inform you that you will be entitled to the rights set out below
                in relation to the processing of your personal data by The
                Pannonian association; ul. Cara Hadrijana 5, 31000 Osijek,
                Croatia.
              </p>

              <p>
                At the same time, pursuant to art. 13 of the Regulation, we
                inform you that:
              </p>

              <p>
                The Pannonian association collects your personal data for the
                following purposes:
              </p>
            </div>

            <div role="presentation">
              <h3>(a) Contact forms</h3>
              <p>
                When sending contact message, we store your e-mail address. The
                e-mail address is stored permanently and is removed at your
                request or in accordance with a change of law;
              </p>
            </div>

            <div role="presentation">
              <h3>(b) Cookies</h3>
              <p>
                If you make a comment on our web site, you may choose to save
                your name, email address and webpage in cookies. This is to make
                it easy for you to comment and and web page. This is so you
                don't have to re-enter this information again. These cookies
                last one year.
              </p>

              <p>
                If you have an account and log in to this site, we will set up a
                temporary cookie to determine if your browser accepts cookies.
                This cookie contains no personal information and is deleted when
                you switch off your browser.
              </p>

              <p>
                When you log in, we will also set a few cookies to store your
                login information and your screen settings. Login cookies take
                two days, and display settings cookies last one year. If you
                choose 'Remember Me', your application will take two weeks. If
                you sign out of your account, your login cookies will be
                removed.
              </p>

              <p>
                If you edit or publish an article, an additional cookie will be
                saved in your browser. This cookie contains no personal
                information and only indicates the publication ID of the article
                you just edited. The cookie expires after 1 day;
              </p>
            </div>

            <div role="presentation">
              <h3>(c) Embedded content from other web sites</h3>
              <p>
                Articles on this site may contain embedded content (eg videos,
                pictures, articles, etc.). Embedded content from other webpages
                behaves the same as if a visitor had visited that other webpage.
              </p>

              <p>
                These sites may collect information about you, use cookies, have
                additional third-party tracking embedded, and monitor your
                interaction with that embedded content, including monitoring
                your interaction with embedded content if you have an account
                and are logged in to the website.
              </p>
            </div>

            <div role="presentation">
              <h3>The recipients of your data</h3>
              <p>
                Your information, which you exclusively share with us, remains
                solely with us and is removed at your request or in accordance
                with a change of law.
              </p>
            </div>

            <div role="presentation">
              <h3>How long do we store your data?</h3>
              <p>
                For users who register with our site (if any), we also store
                personal information that they provide on their user profiles.
                All users can view, edit, or delete their personal information
                at any time (except they cannot change their username).
                Webmasters can also view and edit this information.
              </p>
            </div>

            <div role="presentation">
              <h3>What rights do you have about your data?</h3>
              <p>
                If you have an account on this site or have commented, you may
                request to receive an export file of the personal information we
                hold about you, including any information you have provided to
                us. You may also request the deletion of any personal
                information we hold about you. This does not include any
                information we are required to hold for administrative, legal or
                security purposes.
              </p>
            </div>

            <div role="presentation">
              <h3>Where do we send your data?</h3>
              <p>
                Visitor comments can be verified through an automated spam
                detection service.
              </p>
            </div>
          </div>

          <div role="presentation">
            <h2>Contact Information</h2>
            <p>
              Any additional queries regarding your privacy on this site can be
              sent to <a href="mailto:info@pannonian.org">info@pannonian.org</a>
              .
            </p>
          </div>

          <div className="content__subsection" role="presentation">
            <h2>Additional Information</h2>

            <div role="presentation">
              <h3>How do we protect your information?</h3>
              <p>
                These sites are regularly updated and maintained, reducing the
                possibility of hacking and unauthorized intrusion into our
                system.
              </p>
            </div>

            <div role="presentation">
              <h3>What procedures are in place in case of data breach?</h3>
              <p>
                Our administrators monitor the events on our web site and will
                respond as soon as possible in case of any unwanted activity.
              </p>
            </div>

            <p>
              If you find a mistake on our website, please let us know
              immediately by emailing{" "}
              <a href="mailto:info@pannonian.org">info@pannonian.org</a>.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
