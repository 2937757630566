import React from "react";

import "./PageHeader.scss";

interface Props {
  onPrimary?: boolean;
  title: string;
}

export const PageHeader: React.FC<Props> = ({ onPrimary = true, title }) => {
  return (
    <section className="page-header">
      <div
        className={`page-header__container container ${
          onPrimary ? "text--on-primary" : "text--on-white"
        }`}>
        <h1 className="page-header__title">{title}</h1>
      </div>
    </section>
  );
};
